<template>
  <section class="lightcar-section">
    <div class="container">
      <div class="lightcar-section__inner">
        <div class="lightcar-section__image">
          <img
            src="@/assets/images/lightcar.jpeg"
            style="width: 100%; height: 100%; object-fit: cover;"
            class="about-img"
            alt="mechanic-image"
          >
        </div>
        <div class="lightcar-section__info">
          <div class="lightcar-section__title main__title">
            Лаборатория по составлению протоколов
          </div>
          <div class="lightcar-section__text main__text">
            Испытательная лаборатория «ТЭЦ» предоставляет водителям автотранспортных средств
            протоколы проверки технического состояния транспорта для проведения Государственного
            технического осмотра.
          </div>
          <p class="medium__text price-description">
            от 4000 грн
          </p>
          <button
            class="btn btn--black"
            @click="openModal()"
          >
            Заказать полную оценку
          </button>
        </div>
      </div>
    </div>
    <pop-up />
  </section>
</template>

<script>
export default {
    name: 'LightCarDescr',
    components: {
    PopUp: () => import('@/components/PopUp')
    },
    methods: {
    openModal() {
        document.querySelector('.popUp').classList.add('popUp__active')
        document.querySelector('body').classList.add('lock')
    },
    closeModal() {
        document.querySelector('.popUp').classList.remove('popUp__active')
    }
    }
}
</script>
